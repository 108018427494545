export default {
    switch: {
        lang: "中文"
    },
    table: {
        title: "欢迎",
        accountLog: "账号",
        passwordLog: "密码",
        accountReg: "请输入账号",
        nameReg: "请输入用户名",
        passwordReg: "请输入密码",
        passwordReg2: "请再次输入密码",
        logBut: "登录",
        regBut: "注册",
        reset: "重置"
    },
    CompoundInterestCalculator: {
        title: "复利计算器",
        currencylab: "选择币种",
        Dollar: "美元",
        Yuan: "人民币",
        Philippine_Peso: "菲律宾比索"
      },
    menu: {

    }

}