export default {
    switch: {
        lang: "English"
    },
    table: {
        title: "welcome",
        accountLog: "account",
        passwordLog: "password",
        accountReg: "please type account",
        nameReg: "please type name",
        passwordReg: "please type password",
        passwordReg2: "please type password again",
        logBut: "login",
        regBut: "register",
        reset: "reset"
    },
    CompoundInterestCalculator: {
      title: "Compound Interest Calculator",
      currencylab: "Select Currency",
      Dollar: "Dollar",
      Yuan: "Yuan",
      Philippine_Peso: "Philippine Peso"
    },
    menu: {

    }

}