var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"shell"},[_c('ul',{staticClass:"nav"},[_c('li',{class:{ active: _vm.activeSection === '#logo' },attrs:{"id":"logo"},on:{"click":function($event){return _vm.setActive('#logo')}}},[_vm._m(0)]),_c('li',{class:{ active: _vm.activeSection === '#home' },on:{"click":function($event){return _vm.setActive('#home')}}},[_vm._m(1)]),_c('li',{class:{ active: _vm.activeSection === '#theme' },on:{"click":function($event){return _vm.setActive('#theme')}}},[_vm._m(2)]),_c('li',{class:{ active: _vm.activeSection === '#wallet' },on:{"click":function($event){return _vm.setActive('#wallet')}}},[_vm._m(3)]),_c('li',{class:{ active: _vm.activeSection === '#picture' },on:{"click":function($event){return _vm.setActive('#picture')}}},[_vm._m(4)]),_c('li',{class:{ active: _vm.activeSection === '#code' },on:{"click":function($event){return _vm.setActive('#code')}}},[_vm._m(5)]),_c('li',{class:{ active: _vm.activeSection === '#authentication' },on:{"click":function($event){return _vm.setActive('#authentication')}}},[_vm._m(6)]),_c('li',{class:{ active: _vm.activeSection === '#me' },on:{"click":function($event){return _vm.setActive('#me')}}},[_vm._m(7)])])]),(_vm.activeSection === '#home')?_c('section',{attrs:{"id":"home"}},[_vm._v("Home")]):_vm._e(),(_vm.activeSection === '#theme')?_c('section',{attrs:{"id":"theme"}},[_vm._v("Theme")]):_vm._e(),(_vm.activeSection === '#wallet')?_c('section',{attrs:{"id":"wallet"}},[_vm._v("Wallet")]):_vm._e(),(_vm.activeSection === '#picture')?_c('section',{attrs:{"id":"picture"}},[_vm._v("Picture")]):_vm._e(),(_vm.activeSection === '#code')?_c('section',{attrs:{"id":"code"}},[_vm._v("QR code")]):_vm._e(),(_vm.activeSection === '#authentication')?_c('section',{attrs:{"id":"authentication"}},[_vm._v("Authentication")]):_vm._e(),(_vm.activeSection === '#me')?_c('section',{attrs:{"id":"me"}},[_vm._v("ME")]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_c('div',{staticClass:"icon"},[_c('div',{staticClass:"imageBox"},[_c('img',{attrs:{"src":"","alt":"Goat Image"}})])]),_c('div',{staticClass:"text"},[_vm._v("山羊の前端小窝")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_c('div',{staticClass:"icon"},[_c('i',{staticClass:"iconfont icon-cangku"})]),_c('div',{staticClass:"text"},[_vm._v("Home")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_c('div',{staticClass:"icon"},[_c('i',{staticClass:"iconfont icon-zhuti_tiaosepan"})]),_c('div',{staticClass:"text"},[_vm._v("Theme")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_c('div',{staticClass:"icon"},[_c('i',{staticClass:"iconfont icon-qianbao"})]),_c('div',{staticClass:"text"},[_vm._v("Wallet")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_c('div',{staticClass:"icon"},[_c('i',{staticClass:"iconfont icon-tupian"})]),_c('div',{staticClass:"text"},[_vm._v("Picture")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_c('div',{staticClass:"icon"},[_c('i',{staticClass:"iconfont icon-erweima"})]),_c('div',{staticClass:"text"},[_vm._v("QR code")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_c('div',{staticClass:"icon"},[_c('i',{staticClass:"iconfont icon-dunpaibaoxianrenzheng"})]),_c('div',{staticClass:"text"},[_vm._v("Authentication")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_c('div',{staticClass:"icon"},[_c('div',{staticClass:"imageBox"},[_c('img',{attrs:{"src":"","alt":"Goat Image"}})])]),_c('div',{staticClass:"text"},[_vm._v("ME")])])
}]

export { render, staticRenderFns }